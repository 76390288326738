// JavaScript Document
//=============================================
//  ハンバーガー
//=============================================
// NOTE: 現状はトップ画面以外だと以下のDOMが存在せずにエラーとなるため、存在する場合のみ処理する様にしているが、必要なくなれば削除する
const js_btn_menu = document.getElementById('js_btn_menu');
if (js_btn_menu) {
  js_btn_menu.addEventListener('click', event => {
    js_bars.classList.toggle('!hidden')
    js_xmark.classList.toggle('!hidden')
    js_menu.classList.toggle('translate-x-full')
  });
}

//=============================================
//  検　索
//=============================================
// NOTE: 現状はトップ画面以外だと以下のDOMが存在せずにエラーとなるため、存在する場合のみ処理する様にしているが、必要なくなれば削除する
const js_btn_search = document.getElementById('js_btn_search');
if (js_btn_search) {
  js_btn_search.addEventListener('click', event => {
    js_form_search.classList.toggle('top-[60px]')
    js_form_search.classList.toggle('top-[-100px]')
    js_btn_searchOpen.classList.toggle('!hidden')
    js_btn_searchClose.classList.toggle('!hidden')
    js_btn_searchOpenTxt.classList.toggle('!hidden')
    js_btn_searchCloseTxt.classList.toggle('!hidden')
  });
}

// NOTE: railsのバージョンを上げて5.1以上になったため、yarnで追加したjqueryを使用する
//       なお、jquery-rails gemは不要となるため、追って削除する
import $ from 'jquery';

//=============================================
//	スムーズスクロール（aタグでhref=#id名）
//=============================================
$(document).ready(function () {
  //URLのハッシュ値を取得
  var headerHeight = $("header").outerHeight(); //header 高さ取得
  var urlHash = location.hash;
  //ハッシュ値があればページ内スクロール
  if (urlHash) {
    //スクロールを0に戻す
    $('body,html').stop().scrollTop(0);
    setTimeout(function () {
      //ロード時の処理を待ち、時間差でスクロール実行
      scrollToAnker(urlHash);
    }, 100);
  }

  //通常のクリック時
  $('a[href^="#"]').click(function () {
    //ページ内リンク先を取得
    var href = $(this).attr("href");
    //リンク先が#か空だったらhtmlに
    var hash = href == "#" || href == "" ? 'html' : href;
    //スクロール実行
    scrollToAnker(hash);
    //リンク無効化
    return false;
  });

  // 関数：スムーススクロール
  // 指定したアンカー(#ID)へアニメーションでスクロール
  function scrollToAnker(hash) {
    var target = $(hash).offset();
    var position = target ? target.top : 0;
    $('body,html').stop().animate({ scrollTop: position - headerHeight }, 500);
  }
})


//=============================================
//	トップへ戻るボタン
//=============================================
$(function () {
  "use strict";
  let topBtn = $(".js_pageTop");
  let windowWidth = $(window).width();
  if (windowWidth > 1024) {
    topBtn.hide();
    //スクロールが100に達したらボタン表示
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        topBtn.fadeIn();
      } else {
        topBtn.fadeOut();
      }
    });
  }
  //スクロールしてトップ
  topBtn.click(function () {
    $("body,html").animate({
      scrollTop: 0,
    },
      500
    );
    return false;
  });
});
//=============================================
//    トップへ戻るボタン 位置調整
//=============================================
$(window).on("scroll", function () {
  let windowWidth = $(window).width();
  let doch = $(document).innerHeight(); //ページ全体の高さ
  let winh = $(window).innerHeight(); //ウィンドウの高さ
  let bottom = doch - winh - 100; //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
  // 35:アイコンの高さ調整
  let footerHeight = $(".bl_footer").outerHeight() - 50;
  positionBottom = footerHeight;
  if (windowWidth > 1024) {
    if (bottom <= $(window).scrollTop()) {
      //一番下までスクロールした時に実行
      $(".js_pageTop").css({
        bottom: positionBottom
      });
    } else {
      $(".js_pageTop").css({
        bottom: "5%" //cssで定義したPCの位置を入力
      });
    }
  }
});

//=============================================
//    PC tel リンク解除
//=============================================
let ua = navigator.userAgent.toLowerCase();
let isMobile = /iphone/.test(ua) || /android(.+)?mobile/.test(ua);

if (!isMobile) {
  $('a[href^="tel:"]').on("click", function (e) {
    "use strict";
    e.preventDefault();
  });
}
