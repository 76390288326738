/**
 * リストコンテンツに使用する「もっと読む」開閉機能です。
 * @constructor
 */
export default class ShowMoreList {
  constructor(options) {
    /**
     * デフォルトの値を設定する。
     * @property {string} listsClassName リストコンテンツDOM要素のclass名
     * @property {string} showMoreButtonClassName 「もっと読む」ボタンDOM要素のclass名
     * @property {number} numberToShow 初期表示件数
     */
    this.defaults = {
      listsClassName: null,
      showMoreButtonClassName: null,
      numberToShow: 5,
    }

    /**
     * デフォルトの値をオプション値で上書きする。
     */
    this.settings = Object.assign(this.defaults, options)

    /**
     * リストコンテンツDOM要素を取得する。
     */
    this.lists = document.querySelectorAll(this.settings.listsClassName)

    /**
     * もっと読む」ボタンDOM要素を取得する。
     */
    this.showMoreButtons = document.querySelectorAll(
      this.settings.showMoreButtonClassName
    )

    /**
     * 初期化時に必要なメソッドを実行する。
     */
    this.hiddenListContents()
    this.showListContents()
  }

  /**
   * 初期表示件数を超えるリストコンテンツを非表示にする。
   */
  hiddenListContents() {
    for (let i = this.settings.numberToShow; i < this.lists.length; i++) {
      this.lists[i].classList.add('is-hidden')
    }
  }

  /**
   * 「もっと見る」ボタンを非表示にする。
   * @param {HTMLElement} element 「もっと見る」ボタンのDOM要素
   */
  hiddenShowMoreButton(element) {
    element.classList.add('is-hidden')
  }

  /**
   * 非表示になっているリストコンテンツを表示する。
   */
  showListContents() {
    this.showMoreButtons.forEach((element) => {
      element.addEventListener('click', (event) => {
        /**
         * 「もっと見る」ボタンの末尾の識別子となる数字を取得する。
         */
        const ID = this.getID(event.currentTarget.classList)

        /**
         * 取得した識別子を含むターゲットを変数に代入する。
         */
        const targetLists = document.querySelectorAll(
          `.js-show-more-list-${ID} > .js-show-more-list__item`
        )

        /**
         * 非表示になっているリストコンテンツを表示する。
         */
        for (let i = this.settings.numberToShow; i < targetLists.length; i++) {
          targetLists[i].classList.remove('is-hidden')
        }

        /**
         * 「もっと見る」ボタンを非表示にする。
         */
        this.hiddenShowMoreButton(element)

        /**
         * デフォルトの挙動をキャンセルする。
         */
        event.preventDefault()
      })
    })
  }

  /**
   * 「もっと見る」ボタンをクリック時、[js-]から始まるclass名を判別し、
   * 該当のclass名の末尾の識別子となる数字を取得する。
   * @param {DOMTokenList} classList 対象のDOM要素に指定されている全てのclass名
   * @return {number} target 識別子
   */
  getID(classList) {
    let target
    classList.forEach((className) => {
      if (className.match(/js-/)) {
        target = className.slice(-1)
      }
    })
    return target
  }
}
