/**
 * モバイル版絞り込み検索ボタンをタップ時、検索モーダルを開く関数です。
 */
export default class SearchModal {
  constructor() {
    this.open1stButton = document.getElementById('js-search-modal-open')
    this.modal1stLayer = document.getElementById('js-search-modal-1st-layer')
    this.toggle1stClassName = 'is-search-moda-1st-layer-opened'
  }
  
  init() {
    if (this.open1stButton) {
      this._open()
    }
  }

  /**
   * モーダルを開く。
   *
   * @memberof SearchModal
   */
  _open() {
    // NOTE: SP表示でのみ使用（search_modal）
    const open2ndButton = [
      ...document.querySelectorAll('.p-search-modal__filter--modal-trigger'),
    ]
    const closeButton = [...document.querySelectorAll('.js-search-modal-close')]
    const toggle2ndClassName = 'is-search-moda-2nd-layer-opened'
    const categorySelect = document.getElementById('js-search-modal-category-select')
    const modalTag = document.getElementById('js-search-modal-tags')

    // NOTE: SP/PCの両方で使用
    const tag = document.getElementById('js-search-tags')
    const vodItem = [...document.querySelectorAll('.js-tags-vod')]
    const tagItem = [...document.querySelectorAll('.js-tags-tag')]
    const tagRemoveButton = [...document.querySelectorAll('.c-tags__item')]

    this._open1stLayerModal(this.open1stButton)

    open2ndButton.forEach((element) => {
      element.addEventListener('click', () => {
        const modal2ndLayer = document.getElementById(
          'js-search-modal-2nd-layer-' + element.dataset.index
        )
        if (modal2ndLayer.classList.contains(toggle2ndClassName)) {
          modal2ndLayer.classList.remove(toggle2ndClassName)
          document.body.classList.remove(toggle2ndClassName)
        } else {
          modal2ndLayer.classList.add(toggle2ndClassName)
          document.body.classList.add(toggle2ndClassName)
        }
      })
    })

    closeButton.forEach((element) => {
      element.addEventListener('click', () => {
        const modal2ndLayer = element.closest('[class^="p-search-modal p-search-modal--2nd-layer"]')
        if(modal2ndLayer && modal2ndLayer.classList.contains(toggle2ndClassName)) {
          modal2ndLayer.classList.remove(toggle2ndClassName)
          document.body.classList.remove(toggle2ndClassName)
        } else if (this.modal1stLayer.classList.contains(this.toggle1stClassName)) {
          this.modal1stLayer.classList.remove(this.toggle1stClassName)
          document.body.classList.remove(this.toggle1stClassName)
          document.body.removeAttribute('style')
        }
      })
    })

    categorySelect.addEventListener('change', () => {
      const selected = categorySelect.options[categorySelect.selectedIndex]
      const item_text = selected.textContent

      // NOTE: カテゴリー関係のタグをすべて削除後、選択されたタグを追加する
      const category_tags = [...tag.querySelectorAll('.c-tags__item.c-tags__category')]
      category_tags?.forEach((element) => { element.remove })

      tag.insertAdjacentHTML('beforeend', '<li class="c-tags__item c-tags__category" data-key="' + selected.value + '">' + item_text + '</li>')
      let target_tag = tag.querySelector('.c-tags__item.c-tags__category[data-key="' + selected.value + '"]')
      this._bind_tag_remove_button(target_tag)

      modalTag.insertAdjacentHTML('beforeend', '<li class="c-tags__item c-tags__category" data-key="' + selected.value + '">' + item_text + '</li>')
      target_tag = modalTag.querySelector('.c-tags__item.c-tags__category[data-key="' + selected.value + '"]')
      this._bind_tag_remove_button(target_tag, true)

      this._update_search_url()
    })

    vodItem.forEach((element) => {
      element.addEventListener('click', () => {
        const item_text = element.parentElement.querySelector('label')?.textContent || element.innerHTML.replace(/<span>.*<\/span>/, '')

        // NOTE: 追加しようとしているタグが存在するかをチェック + 取得しておく
        const targetTags = [...document.querySelectorAll('.c-tags__item.c-tags__vod[data-key="' + element.dataset.key + '"]')]

        if (element.checked || element.tagName == 'A') {
          // NOTE: 追加しようとしているタグが存在する場合は、新たに追加はしない
          if (targetTags.length == 0) {
            tag.insertAdjacentHTML('beforeend', '<li class="c-tags__item c-tags__vod" data-key="' + element.dataset.key + '">' + item_text + '</li>')
            let target_tag = tag.querySelector('.c-tags__item.c-tags__vod[data-key="' + element.dataset.key + '"]')
            this._bind_tag_remove_button(target_tag)

            modalTag.insertAdjacentHTML('beforeend', '<li class="c-tags__item c-tags__vod" data-key="' + element.dataset.key + '">' + item_text + '</li>')
            target_tag = modalTag.querySelector('.c-tags__item.c-tags__vod[data-key="' + element.dataset.key + '"]')
            this._bind_tag_remove_button(target_tag, true)
          }
        } else {
          targetTags?.forEach((target_tag) => { target_tag.remove() })
        }

        this._update_search_url(element)
      })
    })

    tagItem.forEach((element) => {
      element.addEventListener('click', () => {
        const item_text = element.parentElement.querySelector('label')?.textContent || element.innerHTML.replace(/<span>.*<\/span>/, '')

        // NOTE: 追加しようとしているタグが存在するかをチェック + 取得しておく
        const targetTags = [...document.querySelectorAll('.c-tags__item.c-tags__tag[data-key="' + element.dataset.key + '"]')]

        if (element.checked || element.tagName == 'A') {
          // NOTE: 追加しようとしているタグが存在する場合は、新たに追加はしない
          if (targetTags.length == 0) {
            tag.insertAdjacentHTML('beforeend', '<li class="c-tags__item c-tags__tag" data-key="' + element.dataset.key + '">' + item_text + '</li>')
            let target_tag = tag.querySelector('.c-tags__item.c-tags__tag[data-key="' + element.dataset.key + '"]')
            this._bind_tag_remove_button(target_tag)

            modalTag.insertAdjacentHTML('beforeend', '<li class="c-tags__item c-tags__tag" data-key="' + element.dataset.key + '">' + item_text + '</li>')
            target_tag = modalTag.querySelector('.c-tags__item.c-tags__tag[data-key="' + element.dataset.key + '"]')
            this._bind_tag_remove_button(target_tag, true)
          }
        } else {
          targetTags?.forEach((target_tag) => { target_tag.remove() })
        }

        this._update_search_url(element)
      })
    })

    // NOTE: タグの×ボタン押下時のメソッド（初期描画時に存在するタグ分）
    tagRemoveButton.forEach((element) => {
      if (element.parentNode.id == 'js-search-modal-tags') {
        this._bind_tag_remove_button(element, true)
      } else {
        this._bind_tag_remove_button(element)
      }
    })
  }

  // NOTE: タグの×ボタンへのイベント付与
  _bind_tag_remove_button(element, is_modal = false) {
    element.addEventListener('click', () => {
      // NOTE: 当該チェックボックスのチェックを解除する
      let xpath = "//input[contains(@class, 'p-filter__item__input') and @data-key='" + element.dataset.key + "']"
      let resultType = XPathResult.FIRST_ORDERED_NODE_TYPE
      const target_checkbox = document.evaluate(xpath, document, null, resultType, null).singleNodeValue
      target_checkbox.checked = false

      // NOTE: 当該タグを取り除く
      xpath = "//li[contains(@class, 'c-tags__item') and @data-key='" + element.dataset.key + "']"
      resultType = XPathResult.ORDERED_NODE_SNAPSHOT_TYPE
      let target_tags = document.evaluate(xpath, document, null, resultType, null)

      let i = 0
      while (true) {
        let li = target_tags.snapshotItem(i)
        if (!li) break
        li.remove()
        i++
      }

      this._update_search_url()
      
      // NOTE: 適当なaタグの実行して再検索を掛けられれば良いため、SP表示用の検索ボタンを使用する
      if (!is_modal) document.getElementById('js-search-modal-button').click()
    })
  }

  // NOTE: 検索ボタンのURLを更新
  _update_search_url(element = null) {
    const searchButton = document.getElementById('js-search-modal-button')

    // NOTE: js-search-modal-tagsもjs-search-tagsも同じタグを持っているはずなので、tagの方を使って処理する
    const tag = document.getElementById('js-search-tags')

    // NOTE: data-key属性（SP） or hidden_field（PC）から引っ張ってくる
    const category_key = tag.querySelector('.c-tags__item.c-tags__category')?.dataset.key || document.getElementById("category_type")?.value

    const vod_keys = [...tag.querySelectorAll('.c-tags__item.c-tags__vod')]?.map((element) => { return 'vods[]=' + element.dataset.key })
    const tag_keys = [...tag.querySelectorAll('.c-tags__item.c-tags__tag')]?.map((element) => { return 'tags[]=' + element.dataset.key })

    const conditions = vod_keys.concat(tag_keys).join('&')

    // TODO: all選択時は一旦暫定でdramaにしているが、階層構造をどうするか考える
    const category_type = category_key != 'all' ? category_key : 'drama'

    if (element) element.href = '/' + category_type + '/search?' + conditions
    searchButton.href = '/' + category_type + '/search?' + conditions
  }

  /**
   * 第1階層レイヤーのモーダルを開く。
   *　
   * @param {HTMLElement} open1stButton 第1階層レイヤーのモーダルを開くボタン
   * @memberof SearchModal
   */
  _open1stLayerModal(open1stButton) {
    open1stButton.addEventListener('click', () => {
      this.modal1stLayer.classList.add(this.toggle1stClassName)
      document.body.classList.add(this.toggle1stClassName)
      document.body.style.overflow = 'hidden'
    })
  }
}
