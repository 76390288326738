/**
 * クリックイベントでclass名をトグルする関数です。
 * @constructor
 */
export default class ToggleClass {
  constructor(options) {
    /**
     * デフォルトの値を設定する。
     * @property {string} triggerHTMLElementClassName トリガーとなるDOM要素のclass名
     * @property {string} toggleClassName クリックしてトグルするclass名
     */
    this.defaults = {
      triggerHTMLElementClassName: null,
      toggleClassName: null,
    }

    /**
     * デフォルトの値をオプション値で上書きする。
     */
    this.settings = Object.assign(this.defaults, options)

    /**
     * トリガーとなるDOM要素を取得する。
     */
    this.elements = document.querySelectorAll(
      this.settings.triggerHTMLElementClassName
    )

  }
  
  init() {
    /**
     * 初期化時に必要なメソッドを実行する。
     */
    this._attachEvent(this.elements, this.settings.toggleClassName)
  }

  /**
   * イベントにメソッドを追加する。
   *
   * @param {NodeList} elements トリガーとなるDOM要素
   * @param {string} className グルするclass名
   * @memberof ToggleClass
   */
  _attachEvent(elements, className) {
    elements.forEach((e) => {
      e.addEventListener('click', (event) => {
        const current = event.currentTarget
        if (!current.classList.contains(className)) {
          this._addClass(current, className)
        } else {
          this._removeClass(current, className)
        }
        event.preventDefault()
      })
    })
  }

  /**
   * class名を追加する。
   * @param {HTMLElement} element トリガーとなるDOM要素
   * @param {string} className 追加するclass名
   * @memberof ToggleClass
   */
  _addClass(element, className) {
    element.classList.add(className)
  }

  /**
   * class名を削除する。
   * @param {HTMLElement} element トリガーとなるDOM要素
   * @param {string} className 削除するclass名
   * @memberof ToggleClass
   */
  _removeClass(element, className) {
    element.classList.remove(className)
  }
}
