
require('channels')
require('@rails/activestorage').start()
import Vue from 'vue'

const Swiper = require('swiper/swiper-bundle.js')
import GetAndSetElementValue from '../get_and_set_element_value'

import SearchModal from '../search_modal'

import GlobalNaviDropdown from '../global_navi_dropdown'
new GlobalNaviDropdown()
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/all'

import ToggleClass from '../toggle_class'
import ToggleContents from '../toggle_contents'
import Accordion from '../accordion'
import ShowMore from '../show_more'
import ShowMoreList from '../show_more_list'
import ToggleModal from '../toggle_modal'
import TabSwitch from '../tab_switch'
import ReviewPostSlider from '../review_post_slider'
import UpdateRange from '../update_range'

// NOTE: 元々以下のライブラリを使用していたが、h2+h3とかの入れ子ができない実装になっていたため、取ってきて一部改変
//       ref → https://github.com/jgallen23/toc
import toc from '../toc'

import '../common'
import '../top'
import '../swiper_config'

// NOTE: railsのバージョンを上げて5.1以上になったため、yarnで追加したjqueryを使用する
//       なお、jquery-rails gemは不要となるため、追って削除する
import $ from 'jquery';

document.addEventListener('turbolinks:load', () => {

  const searchmodal = new SearchModal()
  searchmodal.init()

  /**
   * 機能：classのトグル
   * 使用箇所1：フッター、ハンバーガーメニュー内の「放送予定のコンテンツ一覧」「現在放送中のコンテンツ一覧」「よく検索されるキーワード」
   * 使用箇所2：レビューページ -> イイねボタン、返信ボタン、「返信を表示」ボタン
   **/
  if (document.querySelector('.js-toggle-class')) {
    const toggleclass = new ToggleClass({
      triggerHTMLElementClassName: '.js-toggle-class',
      toggleClassName: 'is-active',
    })
    toggleclass.init()
  }

  /**
   * 機能：classのトグル
   * 使用箇所1：content/:id -> 「このレビューはネタバレを含みます」ボタン
   **/
  if (document.querySelector('.js-toggle-contents-trigger')) {
    const togglecontents  = new ToggleContents({
      triggerHTMLElementClassName: '.js-toggle-contents-trigger',
      toggleClassName: 'is-active',
    })
    togglecontents.init()
  }

  /**
   * 使用箇所：ヘッダー -> ログイン前のマイページアイコンクリック時に開くログイン&サインインモーダル
   **/
  if (document.querySelector('.js-tab-switch')) {
    new TabSwitch()
  }

  /**
   * 使用箇所：ヘッダー -> ログイン前のマイページアイコンクリック時に開くログイン&サインインモーダル
   **/
  if (document.querySelector('.js-signup-login-modal')) {
    const sinupLogin = new ToggleModal(
      document.querySelector('.js-signup-login-modal'),
      document.querySelectorAll('.js-signup-login-modal-open'),
      [...document.querySelectorAll('.js-signup-login-modal-close, .js-signup-login-modal')]
    )

    // ログインやSNS認証関連ボタン押下時に、親要素へバブリングしない様にしておく（バブリングするとmodalが閉じてしまうため）
    document.querySelectorAll('.p-signup-login__inner > .c-tab, .p-signup-login__inner > .p-signup-login__content').forEach((item) => {
      item.addEventListener('click', (event) => {
        event.stopPropagation()
      })
    })
  }

  /**
   * 使用箇所：口コミダイアログ
   **/
  if (document.querySelector('.js-review-post-modal')) {
    const reviewPostModal = new ToggleModal(
      document.querySelector('.js-review-post-modal'),
      document.querySelectorAll('.js-review-post-modal-open'),
      document.querySelector('.js-review-post-modal-close'),
      UpdateRange,
      true
    )
  }

  /**
   * 使用箇所：口コミダイアログ
   **/
  if (document.querySelector('.js-review-post-slider-element')) {
    new ReviewPostSlider()
  }

  /**
   * 使用箇所：content/:id, watch/:id-> キャスト内の出演作品カルーセル
   **/
  if (document.querySelector('#page-content')) {
    new GetAndSetElementValue({
      classNames: {
        getValueElement: '.js-cast-profile-works .js-set-target-width--get-element',
        setValueElement: '.js-cast-profile-works .js-set-target-width--set-element',
      },
      setPropertyName: '--width',
    })
  
    new Swiper('.js-cast-profile-works', {
      slidesPerView: 'auto',
      spaceBetween: 16,
      navigation: {
        nextEl: '.js-cast-profile-works .swiper-button-next',
        prevEl: '.js-cast-profile-works .swiper-button-prev',
      },
    })
  }

  /**
   * 使用箇所：content/:id -> 今放送中のドラマ、人気のドラマ、その他人気動画作品
   **/
  const gasve = new GetAndSetElementValue({
    classNames: {
      getValueElement:
        '.p-main-being-broadcast .js-set-target-width--get-element',
      setValueElement:
        '.p-main-being-broadcast .js-set-target-width--set-element',
    },
    setPropertyName: '--item-flex-basis',
  })
})


/**
 * 使用箇所：TOPページ -> 人気ドラマランキング
 **/
const init_gasve_for_top_page = (idx) => {
  const gasve_for_top_page = new GetAndSetElementValue({
    classNames: {
      getValueElement: '.js-home-work__image.js-home-work__image--' + idx,
      setValueElement: '.js-home-work__link.js-home-work__link--' + idx,
    },
    setPropertyName: '--width',
  })
  gasve_for_top_page._setValue()
}

const init_gasves_for_top_page = () => {
  $('.js-home-work__image').each((idx) => {
    init_gasve_for_top_page(idx + 1)
  })
}

/**
 * 使用箇所：カテゴリートップページ -> 人気ドラマランキングで使用
 **/
const init_gasve_for_category_top_page = (idx) => {
  const gasve_for_top_page = new GetAndSetElementValue({
    classNames: {
      getValueElement:
        '.js-category-work__image.js-category-work__image--' + idx,
      setValueElement: '.js-category-work__link.js-category-work__link--' + idx,
    },
    setPropertyName: '--width',
  })
  gasve_for_top_page._setValue()
}

const init_gasves_for_category_top_page = () => {
  $('.js-category-work__image').each((idx) => {
    init_gasve_for_category_top_page(idx + 1)
  })
}

/**
 * 機能：初期処理
 **/
document.addEventListener('turbolinks:load', function () {
  // NOTE: 目次要素がある&生成されていない場合は、手動でトリガーする
  if (
    $("[class*='toc']").length != 0 &&
    $("[class*='toc']").find('li').length == 0
  ) {
    toc(document.querySelector('[data-toc]'))
  }

  // NOTE: カルーセルのサイズ設定（対象のDOMが存在する場合のみ実施）
  if ($('.js-home-work__image').length > 0) {
    init_gasves_for_top_page()
  }

  // NOTE: カルーセルのサイズ設定（対象のDOMが存在する場合のみ実施）
  if ($('.js-category-work__image').length > 0) {
    init_gasves_for_category_top_page()
  }

  // NOTE: swiperを使っていないページの場合は処理終了
  if ($("[class*='swiper']").length == 0) return

  let homeFirstView = new Swiper('.js-home-carousel', {
    loop: true,
    centeredSlides: true,
    slidesPerView: 1.4,
    spaceBetween: 36,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        return `<span class="${className} p-home-first-view__indicator__item c-indicator__item"></span>`
      },
    },
    breakpoints: {
      600: {
        slidesPerView: 1.8,
        spaceBetween: 48,
      },
      1000: {
        slidesPerView: 2.4,
        spaceBetween: 64,
      },
    },
  })

  let homeFeature = new Swiper('.js-home-feature', {
    slidesPerView: 'auto',
    spaceBetween: 16,
    navigation: {
      nextEl: '.js-home-feature .swiper-button-next',
      prevEl: '.js-home-feature .swiper-button-prev',
    },
  })

  let homeRanking = new Swiper('.js-home-ranking', {
    slidesPerView: 'auto',
    spaceBetween: 16,
    navigation: {
      nextEl: '.js-home-ranking .swiper-button-next',
      prevEl: '.js-home-ranking .swiper-button-prev',
    },
  })

  let homeWork = new Swiper('.js-home-recommend', {
    slidesPerView: 'auto',
    spaceBetween: 16,
    navigation: {
      nextEl: '.js-home-recommend .swiper-button-next',
      prevEl: '.js-home-recommend .swiper-button-prev',
    },
  })

  let categoryWork = new Swiper('.js-category-recommend', {
    slidesPerView: 'auto',
    spaceBetween: 16,
    navigation: {
      nextEl: '.js-category-recommend .swiper-button-next',
      prevEl: '.js-category-recommend .swiper-button-prev',
    },
  })
})

document.addEventListener('turbolinks:load', () => {
  if (document.body.classList.contains('review')) {
    /**
     * 機能：アコーディオン
     * 使用箇所：レビューページ -> 各エピソードのトグルする要素
     * コールバック： ShowMore
     **/
    const init_class_accordion_for_review = (() => {
      new Accordion({
        callback: (_targetParentClassName) => {
          document
            .querySelectorAll(`[class*="js-read-more-content is-accordion-"]`)
            .forEach((element, index) => {
              const content_classes = element.className.split(" ")
              const button_classes = element.parentNode.querySelector(`.js-read-more-button`).className.split(" ")

              new ShowMore({
                targetParentClassName: `${_targetParentClassName}`,
                contentsClassName: `.${content_classes[0]}.${content_classes[1]}`,
                showMoreButtonClassName: `.${button_classes[0]}.${button_classes[1]}`,
                isCallback: true,
              })
            })
        },
      })
    })()

    const init_class_showmore_for_review = (() => {
      document
        .querySelectorAll(`[class*="js-read-more-content-"]`)
        .forEach((element, index) => {
          /**
           * 機能：もっと読む
           * 使用箇所：レビュページ -> 左カラム：あらすじ
           **/
          new ShowMore({
            contentsClassName: `.js-read-more-content-${index + 1}`,
            showMoreButtonClassName: `.js-read-more-button-${index + 1}`,
          })
        })
    })()

    /**
     * 機能：もっと読む（リストコンテンツ型）
     * 使用箇所：レビューページ -> 右カラム：各エピソード右下部の「もっと見る」ボタン
     **/
    new ShowMoreList({
      listsClassName: `.js-show-more-list-1 > .js-show-more-list__item`,
      showMoreButtonClassName: `.js-show-more-list-button-1`,
      numberToShow: 3,
    })
  }

  /**
   * カテゴリートップ、カテゴリーおすすめページの初期化
   **/
  if (
    document.body.classList.contains('category-top') ||
    document.body.classList.contains('category-recommend')
  ) {
    /**
     * 機能：もっと読む（リストコンテンツ型）
     * 使用箇所：カテゴリートップ、カテゴリーおすすめページ -> 左カラム：各「〜探す」下部の「もっと見る」ボタン
     **/
    const init_for_category_sidebar = (() => {
      new ShowMoreList({
        listsClassName: `.js-show-more-list-1 > .js-show-more-list__item`,
        showMoreButtonClassName: `.js-show-more-list-button-1`,
      })
      new ShowMoreList({
        listsClassName: `.js-show-more-list-2 > .js-show-more-list__item`,
        showMoreButtonClassName: `.js-show-more-list-button-2`,
      })
      new ShowMoreList({
        listsClassName: `.js-show-more-list-3 > .js-show-more-list__item`,
        showMoreButtonClassName: `.js-show-more-list-button-3`,
      })
      new ShowMoreList({
        listsClassName: `.js-show-more-list-4 > .js-show-more-list__item`,
        showMoreButtonClassName: `.js-show-more-list-button-4`,
      })
      new ShowMoreList({
        listsClassName: `.js-show-more-list-5 > .js-show-more-list__item`,
        showMoreButtonClassName: `.js-show-more-list-button-5`,
      })
      new ShowMoreList({
        listsClassName: `.js-show-more-list-6 > .js-show-more-list__item`,
        showMoreButtonClassName: `.js-show-more-list-button-6`,
      })
    })()
  }

  /**
   * content/:id、watch/:idページの初期化
   **/
  if(document.querySelector('#page-content')) {
    const init_class_showmore_for_kuchikomi_content_and_watch = (() => {
      document
        .querySelectorAll(`[class*="js-read-more-content"]`)
        .forEach((element, index) => {
          /**
           * 機能：もっと読む
           * 使用箇所：口コミ
           **/
          new ShowMore({
            contentsClassName: `.js-read-more-content-${index + 1}`,
            showMoreButtonClassName: `.js-read-more-button-${index + 1}`,
          })
        })
    })()
    
    const init_class_showmore_for_episode_content_and_watch = (() => {
      document
        .querySelectorAll(`[class*="js-read-more-watch-content"]`)
        .forEach((element, index) => {
          /**
           * 機能：もっと読む
           * 使用箇所：口コミ
           **/
          new ShowMore({
            contentsClassName: `.js-read-more-watch-content-${index + 1}`,
            showMoreButtonClassName: `.js-read-more-watch-button-${index + 1}`,
          })
        })
    })()

    // 上部固定のタブメニューの幅を調整する
    const tabMenu = document.querySelector('.p-tab')
    const main = document.querySelector('.p-main')
    const mainMargin = parseFloat(getComputedStyle(main).paddingLeft) + parseFloat(getComputedStyle(main).paddingRight)
    tabMenu.style.width = (main.offsetWidth - mainMargin) + 'px'

    // NOTE: 各タブにイベントを割り当て
    const tabs = [...document.querySelectorAll('.js-tab-menu')]
    // NOTE: タブ切り替えをやめる（ページ内リンクにする）場合は以下をコメントアウトする
    // const contents = [...document.querySelectorAll('.js-tab-menu_contents')]

    tabs.forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault()

        tabs.forEach((ele) => {
          ele.classList.remove('is-current')
        })

        // NOTE: タブ切り替えをやめる（ページ内リンクにする）場合は以下をコメントアウトする
        // contents.forEach((ele) => {
        //   ele.style.display = 'none'
        // })

        element.classList.add('is-current')
        const target_contents = document.getElementById(element.dataset.key)

        // NOTE: タブ切り替えをやめる（ページ内リンクにする）場合は以下をコメントアウトする
        // target_contents.style.display = 'block'

        // NOTE: 当該タブのコンテンツエリアにスクロールさせる
        const headerHeight = document.querySelector('.l-header').offsetHeight
        const tabMenuHeight = document.querySelector('.p-tab').offsetHeight
        const targetOffsetTop = window.pageYOffset + target_contents.getBoundingClientRect().top - headerHeight - tabMenuHeight;
        window.scrollTo({
          top: targetOffsetTop,
          behavior: "smooth"
        });
      })
    })
  }
})

// NOTE: CDNキャッシュ対策のcsrfトークン再取得処理
//       実装時点では、レビュー投稿でしかpostしていないため、実質それ専用の対策となっている
//       もし他のページでも必要で、formのクラス名が異なるなら、フォーム取得部分の修正が必要
function getCSRFToken() {
  return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}

document.addEventListener('turbolinks:load', function(){
  // NOTE: フォーム取得
  const form = document.querySelector('#sp_header_form');
  if (!form) return;

  form.addEventListener('submit', function(e){
    // NOTE: CSRFトークンを取得し、設定
    const token = getCSRFToken();
    if (!token) {
      console.error('CSRF token not found');
      e.preventDefault();
      return;
    }

    // NOTE: CSRFトークンを設定
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', 'authenticity_token');
    input.setAttribute('value', token);
    form.appendChild(input);
  });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("trix")
// require("@rails/actiontext")
