/**
 * 任意のDOM要素の任意の値を取得し、別の任意のDOM要素に設定します。
 */
export default class GetAndSetElementValue {
  constructor(options) {
    /**
     * デフォルトの値を設定します。
     * @param {string} classNames.getValueElement 値を取得したい要素のclass名
     * @param {string} classNames.setValueElement 値を設定したい要素のclass名
     * @param {string} setPropertyName 取得したい値の名前
     */
    this.defaults = {
      classNames: {
        getValueElement: null,
        setValueElement: null,
      },
      setPropertyName: null,
    }

    /**
     * デフォルトの値をオプション値で上書きします。
     */
    this.settings = Object.assign(this.defaults, options)

    /**
     * 初期化します。
     */
    this._setValue()
  }

  /**
   * 任意の要素の任意の値を取得し、別の任意の要素に設定します。
   */
  _setValue() {
    const { getValueElement, setValueElement } = this.settings.classNames
    const { setPropertyName } = this.settings

    const getValueElementArray = [...document.querySelectorAll(getValueElement)]
    const setValueElementArray = [...document.querySelectorAll(setValueElement)]
    const widthValueArray = getValueElementArray.map((element) => {
      const compStyles = window.getComputedStyle(element)
      const propertyValue = compStyles.getPropertyValue(this.settings.setPropertyName)
      const PropertyValueToNumber = Number(propertyValue.trim().slice(0, -2))

      if (element.offsetWidth > PropertyValueToNumber) {
        return element.offsetWidth
      } else {
        return false
      }
    })

    setValueElementArray.forEach((element, index) => {
      if (widthValueArray[index]) {
        element.style.setProperty(setPropertyName, `${Math.round(widthValueArray[index])}px`)
      }
    })
  }
}
