/**
 * グローバルナビのリンクホバー時にドロップダウンを表示させる関数です。
 */
export default class GlobalNaviDropdown {
  constructor() {
    /**
     * タッチデバイスか否かを判定する関数です。
     */
    const hasTouchScreen = () => {
      if (navigator.maxTouchPoints > 0) {
        return true
      }
      if (navigator.msMaxTouchPoints > 0) {
        return true
      }
      if (window.matchMedia('(pointer:coarse)').matches) {
        return true
      }
      if ('orientation' in window) {
        return true
      }

      return false
    }

    /**
     * タッチデバイスでない時、関数を実行します。
     */
    if (!hasTouchScreen()) {
      document.addEventListener('turbolinks:load', () => {
        this._toggleClass()
      })
    }
  }

  /**
   * リンクにホバーしているか否かでclassの付け外しを行います。
   */
  _toggleClass() {
    const trigger = document.querySelectorAll('.js-dropdown-trigger')

    trigger.forEach((element) => {
      element.addEventListener('mouseenter', (event) => {
        event.target.classList.add('is-active')
      })
      element.addEventListener('mouseleave', (event) => {
        event.target.classList.remove('is-active')
      })
    })
  }
}
