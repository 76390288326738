/**
 * モーダルを開く関数です。
 */
export default class ToggleModal {
  constructor(modal, openTrigger, closeTrigger, Callback, isReview = false) {
    this.modal = modal
    this._open(this.modal, openTrigger, Callback, isReview)

    if (Array.isArray(closeTrigger)) {
      closeTrigger.forEach((element) => {
        this._close(this.modal, element)
      })
    } else {
      this._close(this.modal, closeTrigger)
    }
  }

  _open(modal, openTrigger, Callback, isReview) {
    const trigger = openTrigger

    trigger.forEach((item) => {
      item.addEventListener('click', (event) => {
        modal.classList.add('is-active')
        if (Callback) {
          new Callback()
        }
        if (isReview) {
          // NOTE: レビューモーダルの場合は、data属性を使ってform内のhidden項目を書き換える
          const element = event.currentTarget
          document.getElementById('review_reviewable_type').value = element.dataset.type
          document.getElementById('review_reviewable_id').value = element.dataset.id
        }
        event.preventDefault()
      })
    })
  }

  _close(modal, closeTrigger) {
    const trigger = closeTrigger

    trigger.addEventListener('click', (event) => {
      modal.classList.remove('is-active')
      event.preventDefault()
    })
  }
}
