/**
 * コンテンツの開閉を行うアコーディオン関数です。
 */
export default class Accordion {
  constructor(options) {
    /**
     * デフォルトの値を設定する。
     * @property {string} rootElementClassName ルートとなるDOM要素のclass名
     * @property {string} accordionItemClassName ルート配下の開閉の対象と開閉ボタンを内包するDOM要素のclass名
     * @property {string} accordiontoggleButtonClassName 開閉ボタンとなるDOM要素のclass名
     * @property {string} accordionCollapseClassName 開閉するDOM要素のclass名
     * @property {function} callback コールバック関数
     */
    this.defaults = {
      rootElementClassName: '.js-accordion',
      accordionItemClassName: '.js-accordion__item',
      accordiontoggleButtonClassName: '.js-accordion__button',
      accordionCollapseClassName: '.js-accordion__collapse',
      callback: null,
    }

    /**
     * デフォルトの値をオプション値で上書きする。
     */
    this.settings = Object.assign(this.defaults, options)

    /**
     * ルートとなるDOM要素を取得する。
     */
    this.rootElement = document.querySelectorAll(
      this.settings.rootElementClassName
    )

    this.init(this.settings.callback)
  }

  init(callback) {
    /**
     * コールバック関数が実行済みか否かを判定するハッシュ値を格納する配列。
     * @type {array}
     */
    let flagList = []

    /**
     * flagList内を探索した結果を真偽値で代入する。
     * @type {boolean}
     */
    let flag = false

    this.rootElement.forEach((root) => {
      root
        .querySelectorAll(this.settings.accordionItemClassName)
        .forEach((item) => {
          item
            .querySelector(this.settings.accordiontoggleButtonClassName)
            .addEventListener('click', (event) => {
              item
                .querySelectorAll(this.settings.accordionCollapseClassName)
                .forEach((collapse) => {
                  if (collapse.hasAttribute('hidden')) {
                    this.open(collapse, event)
                  } else {
                    this.close(collapse, event)
                  }
                })

              this.hasExcuted(flag, flagList, event.currentTarget, callback)
              event.preventDefault()
            })
        })
    })
  }

  /**
   *
   * ターゲットとなるDOM要素を表示する。
   * @param {HTMLElement} collapse
   * @param {object} event
   * @memberof Accordion
   */
  open(collapse, event) {
    collapse.removeAttribute('hidden')
    event.currentTarget.classList.add('is-active')
  }

  /**
   *
   * ターゲットとなるDOM要素を非表示する。
   * @param {HTMLElement} collapse
   * @param {object} event
   * @memberof Accordion
   */
  close(collapse, event) {
    collapse.setAttribute('hidden', 'until-found')
    event.currentTarget.classList.remove('is-active')
  }

  /**
   *
   * コールバック関数が実行済みか否かを判定する。
   * @param {boolean} flag flagList内を探索した結果を真偽値で代入する。
   * @param {array} flagList コールバック関数が実行済みか否かを判定するハッシュ値を格納する配列。
   * @param {HTMLElement} currentTarget
   * @param {Function} callback
   * @memberof Accordion
   */
  hasExcuted(flag, flagList, currentTarget, callback) {
    flag = flagList.some((value) => value === currentTarget.hash)

    if (!flag) {
      callback(currentTarget.hash)
      flagList.push(currentTarget.hash)
    }
  }
}
