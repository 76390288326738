/**
 * input[type='range']のつまみをスライドした際のアクティブ領域のスタイリングするクラスです。
 */
export default class UpdateRange {
  constructor() {
    this.range = document.querySelector('.js-review-post-slider-element')

    this.update()

    this.range.addEventListener('change', () => {
      this.update()
    })
  }

  update() {
    const rangeValue = this.range.value
    const rangeWidth = document.querySelector('.js-range-base-bar').clientWidth
    const activeRange = rangeWidth * ((rangeValue * 20) / 100)
    const parameter = `${activeRange}px`
    const activeBar = document.querySelector('.js-range-active-bar')

    activeBar.style.width = parameter
  }
}
