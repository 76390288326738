import ToggleClass from "./toggle_class";

/**
 * クリックイベントでトリガーとなるDOM要素と
 * ターゲットとなるDOM要素のclass名をトグルする関数です。
 *
 * @export
 * @class ToggleContents
 * @extends {ToggleClass}
 */
export default class ToggleContents extends ToggleClass {
  constructor(options) {
    super(options)
  }

  /**
   * イベントにメソッドを追加する。
   *
   * @param {NodeList} elements トリガーとなるDOM要素
   * @param {NodeList} targetlements トリガーとなるDOM要素
   * @param {string} className トグルするclass名
   * @memberof ToggleContents
   */
  _attachEvent(elements, className) {
    elements.forEach((e) => {
      e.addEventListener('click', (event) => {
        const current = event.currentTarget
        if (!current.classList.contains(className)) {
          super._addClass(current, className)
          super._addClass(current.nextElementSibling, className)
        } else {
          super._removeClass(current, className)
          super._removeClass(current.nextElementSibling, className)
        }
        event.preventDefault()
      })
    })
  }
}