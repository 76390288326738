
//=============================================
//	swipe設定
//=============================================
const Swiper = require('swiper/swiper-bundle.js')
// いま旬のおすすめ見逃し配信
var topSwiper = new Swiper(".js_season_slide", {
  loop: true,
  centerMode: true,
  effect: "slide",
  spaceBetween: 16,
  slidesPerView: 1.5,
  centeredSlides: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  // ナビボタン
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  breakpoints: {
    // 768px以上の場合
    768: {
      slidesPerView: 3,
      centeredSlides: true,
    },
    // 1024px以上の場合
    1024: {
      slidesPerView: 3.9,
      spaceBetween: 25,
      centeredSlides: true,
    }
  }
});

//ネットで観れるおすすめ映画
var topSwiper = new Swiper(".js_recomended", {
  loop: true,
  centerMode: true,
  effect: "slide",
  spaceBetween: 16,
  slidesPerView: 1.5,
  centeredSlides: true,
  // ナビボタン
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  breakpoints: {
    // 768px以上の場合
    768: {
      slidesPerView: 3,
    },
    // 1024px以上の場合
    1024: {
      slidesPerView: 5,
      spaceBetween: 25,
      centeredSlides: true,
    }
  }
});


//ジャンルから観たい作品・配信を探す
var topSwiper = new Swiper(".js_genre_slide", {
  slidesPerView: 2.2,
  // ナビボタン
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  breakpoints: {
    // 768px以上の場合
    768: {
      slidesPerView: 3.5,
    },
    // 1024px以上の場合
    1024: {
      slidesPerView: 5
    }
  }
});

//ジャンルから観たい作品・配信を探す
var topSwiper = new Swiper(".js_populartag_slide", {
  slidesPerView: 2.2,
  // ナビボタン
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  },
  breakpoints: {
    // 768px以上の場合
    768: {
      slidesPerView: 3.5,
    },
    // 1024px以上の場合
    1024: {
      slidesPerView: 5
    }
  }
});

//=============================================
//	スライド高さ揃え jquery.matchHeight.js
//=============================================
// $(function () {
//   $('#sec-showcase .box-slide li a').matchHeight();
// });
