/**
 * テキストに使用する「もっと読む」機能です。
 */
export default class ShowMore {
  constructor(options) {
    /**
     * デフォルトの値を設定する。
     * @property {string} contentsClassName テキストコンテンツDOM要素のclass名
     * @property {string} showMoreButtonClassName 「もっと見る」ボタンDOM要素のclass名
     * @property {number} rowToShow 初期表示行数
     * @property {string} clamp 省略記号
     * @property {boolean} isCallback コールバック関数として実行されているか否か
     *
     * @memberof ShowMore
     */
    this.defaults = {
      targetParentClassName: null,
      contentsClassName: null,
      showMoreButtonClassName: null,
      rowToShow: 2,
      clamp: '…',
      isCallback: false,
    }

    /**
     * デフォルトの値をオプション値で上書きする。
     */
    this.settings = Object.assign(this.defaults, options)

    /**
     * テキストコンテンツDOM要素を取得する。
     */
    this.getTextContentsDOM()

    /**
     * 「もっと読む」ボタンDOM要素を取得する。
     */
    this.getShowMoreButtonsDOM()

    this.contents.forEach((text) => {
      const style = window.getComputedStyle(text)
      const fontsize = parseFloat(style.fontSize)
      const width = parseFloat(style.width)
      const letterSpacing = parseFloat(style.letterSpacing)
      const wordCount = letterSpacing
        ? Math.floor(width / (fontsize + letterSpacing)) *
          this.settings.rowToShow
        : Math.floor(width / fontsize) * this.settings.rowToShow
      const textContent = text.textContent

      if (text.innerText.length > wordCount) {
        let str = text.innerText
        str = str.substr(0, wordCount - 1)
        text.innerText = str + this.settings.clamp

        this.showMoreButtons.forEach((more) => {
          more.addEventListener('click', (event) => {
            const currentTarget = event.currentTarget
            currentTarget.style.display = 'none'
            currentTarget.previousElementSibling.innerHTML = textContent
            event.preventDefault()
          })
        })
      } else {
        text.nextElementSibling.style.display = 'none'
      }
    })
  }

  /**
   * テキストコンテンツDOM要素を取得する。
   *
   * @memberof ShowMore
   */
  getTextContentsDOM() {
    /**
     * コールバック関数として実行されているか否かで
     * DOMの取得方法を変える。
     */
    if (this.settings.isCallback) {
      /**
       * class Accordion のコールバック関数を想定。
       * アコーディオンが開かれた要素を実行対象とするために、
       * アコーディオンの開閉ボタンとなるDOM要素に設定されている
       * href属性を取得して対象要素を特定する。
       */
      const selector = document.querySelector(
        `[href="${this.settings.targetParentClassName}"]`
      ).parentElement.nextElementSibling
      this.contents = selector.querySelectorAll(this.settings.contentsClassName)
    } else {
      this.contents = document.querySelectorAll(this.settings.contentsClassName)
    }
  }

  /**
   * 「もっと読む」ボタンDOM要素を取得する。
   *
   * @memberof ShowMore
   */
  getShowMoreButtonsDOM() {
    this.showMoreButtons = document.querySelectorAll(
      this.settings.showMoreButtonClassName
    )
  }
}
