// NOTE: railsのバージョンを上げて5.1以上になったため、yarnで追加したjqueryを使用する
//       なお、jquery-rails gemは不要となるため、追って削除する
import $ from 'jquery';

// JavaScript Document
//=============================================
//    tab
//=============================================
$(function () {
  $('.js_tab').on('click', function () {
    var tabWrap = $(this).parents('.js_tab_wrap');
    var tabBtn = tabWrap.find(".js_tab");
    var tabContents = tabWrap.find('.js_panel');
    tabBtn.removeClass('is-active');
    $(this).addClass('is-active');
    var elmIndex = tabBtn.index(this);
    tabContents.removeClass('is-show');
    tabContents.eq(elmIndex).addClass('is-show');
  });
});

//=============================================
//    more
//=============================================
$(window).on('load resize', function () {
  var windowWidth = $(window).width();
  // ブレイクポイント
  var windowSm = 768;
  if (windowWidth < windowSm) {
    var init = 12; //最初に表示する件数
    var more = 2;  //clickごとに表示したい件数
    // 初期表示数以降のリスト内容を非表示に
    $("#js_vodList > li:nth-child(n+" + (init + 1) + ")").hide()

    //初期表示数以下であればMoreボタンを非表示
    $("#js_vodList").filter(function () {
      return $(this).find("li").length <= init
    }).find(".js_btn_more").hide()

    // Moreボタンクリックで指定数表示
    $(".js_btn_more").on("click", function () {
      let this_list = $("#js_vodList")
      this_list.find("li:hidden").slice(0, more).slideToggle()

      console.log(this_list.find("li:hidden").length);

      if (this_list.find("li:hidden").length == 0) {
        $(this).fadeOut();
        $(".js_btn_more").hide()
        $(this).classList.toggle('hidden')
      }
    })
  }
});