/**
 * 口コミ投稿のスコアのスライダーに連動して
 * 値を変更する関数です。
 */

export default class ReviewPostSlider {
  constructor() {
    const inputElement = document.querySelector(
      '.js-review-post-slider-element'
    )
    const currentValueElement = document.querySelector(
      '#js-review-post-slider-score'
    )

    inputElement.addEventListener('input', (event) => {
      this.rangeOnChange(currentValueElement, event)
    })
    this.setCurrentValue(currentValueElement, inputElement.value)
  }

  setCurrentValue(currentValueElement, val) {
    currentValueElement.innerText = Number(val).toFixed(1)
  }

  rangeOnChange(currentValueElement, event) {
    this.setCurrentValue(currentValueElement, event.target.value)
  }
}
