/**
 * Tab UIの切り替えを行う関数です。
 */
export default class TabSwitch {
  constructor() {
    this._attach()
  }

  _attach() {
    const tabSwitchAll = document.querySelectorAll('.js-tab-switch')

    tabSwitchAll.forEach((tabSwitch) => {
      tabSwitch.addEventListener('click', (event) => {
        this._switch(tabSwitchAll, event.currentTarget)

        event.preventDefault()
      })
    })
  }

  _switch(tabSwitchAll, currentTarget, toggleClassName = 'is-active') {
    const tabContentAll = document.querySelectorAll('.js-tab-content')

    tabSwitchAll.forEach((clickedTabSwitch) => {
      clickedTabSwitch.classList.remove(toggleClassName)
    })

    tabContentAll.forEach((clickedtabContent) => {
      clickedtabContent.classList.remove(toggleClassName)
    })

    if (!currentTarget.classList.contains(toggleClassName)) {
      currentTarget.classList.add(toggleClassName)
      document
        .querySelector(currentTarget.getAttribute('href'))
        .classList.add(toggleClassName)
    }
  }
}
